import { post, get,deletes, downFile } from 'common/request'

export default {
 //分页查询
list: args => get('/scCellManagement/queryList', args),
//根据ID查询
getById: id => get(`/scCellManagement/getById?id=${id}`),
//新增
add: args => post('/scCellManagement/save', args),
//修改
update: args => post('/scCellManagement/save', args),
 //根据ID删除
deleteById: id => post(`/scCellManagement/delete?id=${id}`),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),

}
