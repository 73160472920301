<!--小区管理  -->
<template>
  <div class="">
    <ax-table
      ref="roleTable"
      :show-search="true"
      :clickable="false"
      :showToolBar="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      @action-column-click="actionColumnClick"
      @bulkDeletion="deleteSecurityPersonnel"
      @add="add"
      @pop-confirm="popConfirm"
       :scroll="{ y: '55vh', x: '80vw' }"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
    </ax-table>
    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="新增小区"
      :visible.sync="dialogAdd"
      width="30%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable"
    /></el-dialog>
    <!-- 编辑跳转弹窗 -->

    <addedit ref="edit" @refreshTable="refreshTable"></addedit>
  </div>
</template>
    <script>
import api from "./api";
import axTable from "@/components/ax-table";
import addPage from "./addPage.vue";
import addedit from "./addedit.vue";

const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "key",
    options: { placeholder: "请输入小区名称/所属网格.." },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "创建人",
    type: "",
    model: "createBy",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, addedit, axTable },
  data() {
    //这里存放数据
    return {
      api,
      dialogAdd: false,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [
        { name: "add", text: "新增" },
        { name: "bulkDeletion", text: "批量删除", type: "#FA5959" },
      ],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "小区名称",
            dataIndex: "cellName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "详细地址",
            dataIndex: "address",
            ellipsis: false,
            width: 80,
          },
          {
            title: "总楼栋数",
            dataIndex: "buildingCount",
            ellipsis: false,
            width: 80,
          },
          {
            title: "创建日期",
            dataIndex: "createTime",
            ellipsis: false,
            width: 80,
          },
          {
            title: "创建人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 80,
          },
        ],
        true,
        {
          width: 120,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#2A5CFF",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },

            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  link: true,
                  type: "#F95A5A",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: {},
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //编辑
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.ids(that.selectedRowKeys.join(","));
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.roleTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>