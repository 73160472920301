<!-- 编辑小区 -->
<template>
  <el-dialog
    title="编辑小区"
    :visible.sync="editShow"
    width="30%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <ax-form ref="formBox" :formBuilder="formBuilder">
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          :display-render="displayRender"
          expand-trigger="hover"
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "./api";

const formList = [
  {
    label: "小区名称",
    type: "",
    model: "cellName",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "所属小区不能为空" }],
  },
  {
    label: "所属网格",
    type: "",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 24 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },
  {
    label: "详细地址",
    type: "textarea",
    model: "address",
    options: { placeholder: "请输入详细地址", minRows: 4, maxRows: 4 },
    col: { span: 24 },
    rules: [{ required: true }],
  },
];
export default {
  data() {
    return {
      api,
      id: "",
      gridId: "",
      editShow: false,
      saveType: false,
      address: "",
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
      options: [],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
  },
  methods: {
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 打开弹窗
    openModal(record = {}) {
      this.id = record.id;
      this.getData();
      this.$nextTick(() => {
        this.editShow = true;
      });
    },
    //查看
    async getData() {
      const res = await api.getById(this.id);
      this.businessId = this.id;
      this.$refs.formBox.resetFields();
      // 模拟获取的gridId
      this.gridId = res.data.gridId; // 假设这是你的目标 ID
      // 获取网格树数据
      const gridTreeRes = await api.getTreeList();
      if (gridTreeRes.success) {
        const gridTreeData = this.processData(gridTreeRes.data); // 处理数据
        //找到父级 //递归地遍历树结构数据，并构建从根节点到目标节点的路径
        const findNodePath = (data, targetValue, path = []) => {
          for (const node of data) {
            const currentPath = [...path, node.value];
            if (node.value === targetValue) {
              return currentPath;
            }
            if (node.children && node.children.length > 0) {
              const result = findNodePath(
                node.children,
                targetValue,
                currentPath
              );
              if (result) {
                return result;
              }
            }
          }
          return null;
        };

        //所有id
        const nodePath = findNodePath(gridTreeData, this.gridId);

        this.$refs.formBox.setFieldsValue({
          ...res.data,
          options: nodePath,
        });
      } else {
        console.error("获取网格树数据失败:", gridTreeRes.message);
      }
    },

    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.id = this.id;
        value.gridId = this.gridId;
        try {
          const res = await api.update(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.saveType = false;
            this.closeDialog();
            this.refreshTable();
          } else {
            this.$message.error("编辑失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("编辑失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.editShow = false;
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-form input[type="file"] {
  display: none !important;
}
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
.el-upload__tip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2%;
  margin-top: 4%;
  span {
    font-weight: 400;
    font-size: 12px;
    color: #bbbdbf;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
    